/*=========================================================================================
  File Name: moduleAuthActions.js
  Description: Auth Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import jwt from '../../http/requests/auth/jwt/index.js';
import router from '@/router';
import axios from '../../../src/axios.js';

export default {

  initConfirmReset({commit}, payload) {
    return new Promise(async (resolve, reject) => {

      axios.post(`auth/reset/${payload.code}`, {recaptcha: payload.recaptcha, password: payload.password})
        .then((a) => {

          if (a.response) a = a.response;

          if (a.status === 200) {
            resolve({message: 'Account reset confirmed!'});
            router.push('/login').catch(() => {
            });
          } else {
            reject({error: a});
          }
        })
        .catch(b => {

          if (b.response) {

            b = b.response;

            if (b.status === 404) {
              reject({message: 'No confirmation request found'});
            } else if (b.status === 400) {
              router.push('/login').catch(() => {
              });
              resolve({message: 'Account already confirmed, redirecting...'});
            }
          }

        });
    });
  },
  initConfirmAccount({commit}, payload) {
    return new Promise(async (resolve, reject) => {

      axios.post(`auth/confirm/${payload.code}`, {recaptcha: payload.recaptcha})
        .then((a) => {

          if (a.response) a = a.response;

          if (a.status === 200) {
            resolve({message: 'Account confirmed!'});

            localStorage.removeItem('accessToken');
            localStorage.removeItem('refreshToken');
            localStorage.removeItem('userInfo');
            router.push('/login').catch(() => {
            });
          } else {
            reject({error: a});
          }
        })
        .catch(b => {

          if (b.response) {

            b = b.response;

            if (b.status === 404) {
              reject({message: 'No confirmation request found'});
            } else if (b.status === 400) {
              router.push('/login').catch(() => {
              });
              resolve({message: 'Account already confirmed, redirecting...'});
            }
          }

        });
    });
  },

  refreshUserObject({commit}) {
    return new Promise((resolve, reject) => {

      jwt.refreshUserObject()
        .then(response => {
          if (response.data.data) {
            commit('UPDATE_USER_INFO', response.data.data, {root: true});
            return resolve(response.data.data);
          }

          reject(response.data);
        })
        .catch(exception => reject(exception))
    })
  },

  // JWT
  loginJWT({commit}, payload) {

    return new Promise((resolve, reject) => {
      jwt.login(payload.email, payload.password, payload.tfa, payload.recaptcha)
        .then(response => {

          // If there's user data in response
          if (response.data.data) {
            // Navigate User to homepage

            const data = response.data.data;

            console.log(`Setting access token from login:`, data.tokens);

            // Set accessToken
            localStorage.setItem('accessToken', data.tokens.access_token);
            localStorage.setItem('refreshToken', data.tokens.refresh_token);

            if (data.user.first_name) {
              data.user.full_name = `${data.user.first_name} ${data.user.last_name}`;
            }

            // Update user details
            commit('UPDATE_USER_INFO', data.user, {root: true});

            // Set bearer token in axios
            commit('SET_BEARER', data.tokens.access_token);

            resolve(response);
          } else {
            reject({message: 'Wrong Email or Password'});
          }

        })
        .catch(error => {
          reject(error);
        });
    });
  },
  registerUserJWT({commit}, payload) {

    const {first_name, last_name, email, password, confirm_password, recaptcha} = payload.userDetails;

    return new Promise((resolve, reject) => {

      // Check confirm password
      if (password !== confirm_password) {
        reject({message: 'Password doesn\'t match. Please try again.'});
      }

      jwt.registerUser(first_name, last_name, email, password, recaptcha)
        .then(response => {
          // Redirect User
          router.push(router.currentRoute.query.to || '/confirm/');
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  fetchAccessToken({commit}) {
    if (router.currentRoute.path === '/login' || router.currentRoute.path === '/register') {
      return Promise.reject();
    }

    let accessToken = localStorage.getItem('accessToken');
    let refreshToken = localStorage.getItem('refreshToken');

    if (!refreshToken || refreshToken === 'undefined') {

      router.push('/login');
      return Promise.reject();
    }

    if (accessToken && refreshToken) {
      return {accessToken, refreshToken};
    }

    return new Promise((resolve, reject) => {
      jwt.refreshToken()
        .then(response => {
          resolve(response.data.tokens);
        })
        .catch(ex => reject(ex));
    });
  },
};
