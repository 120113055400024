/* eslint-disable */
export default {
  pages: {
    key: "title",
    data: [
      // DASHBOARDS
      {title: "User Dashboard",        url: "/",                icon: "HomeIcon",           is_bookmarked: true},
      {title: "FAQ Page",              url: "/faq",             icon: "HelpCircleIcon",     is_bookmarked: true},
    ]
  },
  files: {
    key:"file_name",
    data: []
  },
  contacts: {
    key:"name",
    data: []
  },
}
/* eslint-enable */
