import Vue from 'vue';
import axios from '../../../axios/index.js';
import store from '../../../../store/store.js';

// Token Refresh
let isAlreadyFetchingAccessToken = false;
let subscribers = [];

function onAccessTokenFetched(accessToken) {
  subscribers = subscribers.filter(callback => callback(accessToken));
}

function addSubscriber(callback) {
  subscribers.push(callback);
}

export default {
  init() {

    if (localStorage['accessToken'] !== undefined) {
      axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage['accessToken']}`;

      if (Vue.prototype.$gateway === undefined) {
        Vue.prototype.$gateway = new window.Gateway({
          service: 'rapidportal',
          accessToken: localStorage['accessToken'],
          autoConnect: true,
          autoReconnect: true,
          autoSendBrowsing: true,
        });
      }
    }

    axios.interceptors.response.use((response) => {
      return response;
    }, (error) => {

      // const { config, response: { status } } = error
      const {config, response} = error;
      const originalRequest = config;

      if (response && response.status === 401) {
        if (!isAlreadyFetchingAccessToken) {
          isAlreadyFetchingAccessToken = true;
          store.dispatch('auth/fetchAccessToken')
            .then((tokens) => {
              isAlreadyFetchingAccessToken = false;

              // console.log(`Setting access and refresh tokens from refreshTokens store request:`, tokens);
              // localStorage.setItem('accessToken', tokens.accessToken);
              // localStorage.setItem('refreshToken', tokens.refreshToken)

              onAccessTokenFetched(tokens.accessToken);
            })
            .catch(exception => {
              isAlreadyFetchingAccessToken = false;

              if (!exception) return;

              if (exception.response) {
                return
              }

              console.error(`Failed to refresh JWT:`, exception);
            });
        }

        return new Promise((resolve) => {
          addSubscriber(accessToken => {
            axios.defaults.headers.common['Authorization'] = accessToken;
            originalRequest.headers.Authorization = `Bearer ${accessToken}`;
            resolve(axios(originalRequest));
          });
        });
      } else if (response && response.status === 429) {
        let time = response.data.data.resets;

        vm.$vs.notify({
          color: 'danger',
          title: 'You are being ratelimited',
          text: `You must wait ${this.timeToGo(time * 1000)} to perform this action again.`,
        });
      }
      return Promise.reject(error);
    });

  },
  timeToGo(diff) {

    const hours = diff / 3.6e6 | 0;
    const mins = diff % 3.6e6 / 6e4 | 0;
    const secs = Math.round(diff % 6e4 / 1e3);

    let display = '';

    if (hours > 0) {
      display += `${hours} hour${hours === 1 ? '' : 's'}`;
    }
    if (mins > 0) {
      display += `${mins} minute${mins === 1 ? '' : 's'}`;
    }
    if (secs > 0) {
      display += `${mins > 0 || hours > 0 ? ' and' : ''} ${secs} second${secs === 1 ? '' : 's'}`;
    }

    return display;
  },

  refreshUserObject() {
    return axios.get('users/@me');
  },

  login(email, password, tfa, recaptcha) {
    return axios.post('auth/login', {email, password, tfa, recaptcha});
  },

  registerUser(first_name, last_name, email, password, recaptcha) {
    return axios.post('auth/register', {
      first_name, last_name, email, password, recaptcha,
    });
  },

  refreshToken() {

    const token = localStorage.getItem('refreshToken');

    if (!token) return Promise.reject('No existing refresh token');

    return axios.post('auth/refresh', {
      refresh: token,
    });
  },
};
