// axios
import axios from 'axios';

let baseUrl = 'https://portal-api.rapid.education/v1/';

if (location.href.includes('frontend.pages.dev')) {
  baseUrl = 'https://portal-api-staging.rapid.education/v1/';
} else if (location.href.includes('-dev')) {
  baseUrl = 'https://portal-api-dev.rapid.education/v1/';
}

export default axios.create({baseURL: baseUrl});
