import Vue from 'vue'
import Router from 'vue-router'
import store from '@/store/store'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior () {
    return {x: 0, y: 0}
  },
  routes: [

    {
      // =============================================================================
      // MAIN LAYOUT ROUTES
      // =============================================================================
      path: '',
      component: () => import('./layouts/main/Main.vue'),
      children: [
        // =============================================================================
        // Theme Routes
        // =============================================================================
        {
          path: '/',
          redirect: '/dashboard'
        },
        {
          path: '/seminars',
          name: 'seminars',
          beforeEnter(to, from, next) {
            window.location.replace("https://app.rapidseminars.com/dashboard")
          },
          meta: {
            rule: 'user',
            pageTitle: 'Services',
            authRequired: true
          }
        },
        {
          path: '/dashboard',
          name: 'dashboard',
          component: () => import('./views/pages/Services'),
          // component: () => import('./views/DashboardUser.vue'),
          meta: {
            rule: 'user',
            pageTitle: 'Services',
            authRequired: true
          }
        },
        {
          path: '/shop',
          name: 'shop',
          component: () => import('./views/apps/shop/ECommerceShop'),
          // component: () => import('./views/DashboardUser.vue'),
          meta: {
            rule: 'user',
            authRequired: true,
            pageTitle: 'Shop'
          }
        },
        {
          path: '/shop/:productId',
          name: 'shop_product',
          component: () => import('./views/apps/shop/ECommerceItemDetailView'),
          // component: () => import('./views/DashboardUser.vue'),
          meta: {
            rule: 'user',
            authRequired: true,
            pageTitle: 'Shop Item'
          }
        },
        {
          path: '/checkout',
          name: 'checkout',
          component: () => import('./views/apps/shop/ECommerceCheckout'),
          // component: () => import('./views/DashboardUser.vue'),
          meta: {
            rule: 'user',
            pageTitle: 'Checkout',
            authRequired: true
          }
        },
        {
          path: '/partner',
          name: 'partner',
          component: () => import('./views/apps/self-partner/PartnerView'),
          meta: {
            rule: 'partner',
            authRequired: true,
            pageTitle: 'Partner View'
          }
        },
        {
          path: '/partner/products',
          name: 'partner-products',
          component: () => import('./views/apps/self-partner/product-list/ProductList'),
          meta: {
            rule: 'partner',
            authRequired: true,
            pageTitle: 'Product List'
          }
        },
        {
          path: '/partner/edit',
          name: 'partner-products',
          component: () => import('./views/apps/self-partner/partner-edit/PartnerEdit'),
          meta: {
            rule: 'partner',
            authRequired: true,
            pageTitle: 'Partner Edit'
          }
        },
        {
          path: '/partner/coupons',
          name: 'partner-coupons',
          component: () => import('./views/apps/self-partner/coupon-list/CouponList'),
          meta: {
            rule: 'partner',
            authRequired: true,
            pageTitle: 'Coupon List'
          }
        },
        {
          path: '/invoices',
          name: 'invoices',
          component: () => import('./views/apps/self-invoices/invoice-list/InvoiceList'),
          // component: () => import('./views/DashboardUser.vue'),
          meta: {
            rule: 'user',
            pageTitle: 'Invoice List',
            authRequired: true
          }
        },
        {
          path: '/invoices/:invoiceId',
          name: 'invoices',
          component: () => import('./views/apps/self-invoices/invoice-view/Invoice'),
          // component: () => import('./views/DashboardUser.vue'),
          meta: {
            rule: 'user',
            pageTitle: 'Invoice View',
            authRequired: true
          }
        },
        {
          path: '/subscriptions',
          name: 'app-self-subscription-list',
          component: () => import('@/views/apps/self-subscriptions/subscription-list/SubscriptionList'),
          meta: {
            rule: 'user',
            breadcrumb: [
              {title: 'Home', url: '/'},
              {title: 'Subscriptions', url: '/subscriptions'},
              {title: 'List', active: true}
            ],
            pageTitle: 'Subscription List',
            authRequired: true
          }
        },
        {
          path: '/subscriptions/:subscriptionId',
          name: 'app-self-subscription-get',
          component: () => import('@/views/apps/self-subscriptions/subscription-view/Subscription'),
          meta: {
            breadcrumb: [
              {title: 'Home', url: '/'},
              {title: 'Subscriptions', url: '/subscriptions'},
              {title: 'List', active: true}
            ],
            pageTitle: 'Subscription View',
            authRequired: true
          }
        },
        {
          path: '/dashboard/ecommerce',
          name: 'dashboard-ecommerce',
          component: () => import('./views/DashboardECommerce.vue'),
          meta: {
            rule: 'admin',
            authRequired: true
          }
        },

        // =============================================================================
        // Application Routes
        // =============================================================================
        {
          path: '/a/subscriptions',
          name: 'app-subscription-list',
          component: () => import('@/views/apps/accounting/subscription-list/SubscriptionList'),
          meta: {
            breadcrumb: [
              {title: 'Home', url: '/'},
              {title: 'Subscriptions', url: '/a/subscriptions'},
              {title: 'List', active: true}
            ],
            pageTitle: 'Subscription List',
            rule: 'accountant',
            authRequired: true
          }
        },
        {
          path: '/a/subscriptions/:subscriptionId',
          name: 'app-subscription-get',
          component: () => import('@/views/apps/accounting/subscription-view/Subscription'),
          meta: {
            breadcrumb: [
              {title: 'Home', url: '/'},
              {title: 'Subscriptions', url: '/a/subscriptions'},
              {title: 'List', active: true}
            ],
            pageTitle: 'Subscription View',
            rule: 'accountant',
            authRequired: true
          }
        },
        {
          path: '/a/subscriptions/:subscriptionId/edit',
          name: 'app-subscription-edit',
          component: () => import('@/views/apps/product/product-list/ProductList'),
          meta: {
            breadcrumb: [
              {title: 'Home', url: '/'},
              {title: 'Subscriptions', url: '/a/subscriptions'},
              {title: 'List', active: true}
            ],
            pageTitle: 'Subscription Edit',
            rule: 'accountant',
            authRequired: true
          }
        },
        {
          path: '/a/products',
          name: 'app-product-list',
          component: () => import('@/views/apps/product/product-list/ProductList'),
          meta: {
            breadcrumb: [
              {title: 'Home', url: '/'},
              {title: 'Products', url: '/a/products'},
              {title: 'List', active: true}
            ],
            pageTitle: 'Product List',
            rule: 'accountant',
            authRequired: true
          }
        },
        {
          path: '/a/products/create',
          name: 'app-product-create',
          component: () => import('@/views/apps/product/product-create/ProductCreate'),
          meta: {
            breadcrumb: [
              {title: 'Home', url: '/'},
              {title: 'Products', url: '/a/products'},
              {title: 'Create', active: true}
            ],
            pageTitle: 'Product Create',
            rule: 'accountant',
            authRequired: true
          }
        },
        {
          path: '/a/products/:productId',
          name: 'app-product-view',
          component: () => import('@/views/apps/product/ProductView.vue'),
          meta: {
            breadcrumb: [
              {title: 'Home', url: '/'},
              {title: 'Products', url: '/a/products'},
              {title: 'View', active: true}
            ],
            pageTitle: 'Product View',
            rule: 'partner',
            authRequired: true
          }
        },
        {
          path: '/a/products/:productId/edit',
          name: 'app-product-edit',
          component: () => import('@/views/apps/product/product-edit/ProductEdit.vue'),
          meta: {
            breadcrumb: [
              {title: 'Home', url: '/'},
              {title: 'Products', url: '/a/products'},
              {title: 'Edit', active: true}
            ],
            pageTitle: 'Product Edit',
            rule: 'partner',
            authRequired: true
          }
        },

        {
          path: '/a/invoices',
          name: 'invoices',
          component: () => import('./views/apps/accounting/invoice-list/InvoiceList'),
          // component: () => import('./views/DashboardUser.vue'),
          meta: {
            rule: 'user',
            pageTitle: 'Invoice List',
            authRequired: true
          }
        },
        {
          path: '/a/invoices/:invoiceId',
          name: 'invoices',
          component: () => import('./views/apps/accounting/invoice-view/Invoice'),
          // component: () => import('./views/DashboardUser.vue'),
          meta: {
            rule: 'user',
            pageTitle: 'Invoice View',
            authRequired: true
          }
        },
        {
          path: '/a/services',
          name: 'app-service-list',
          component: () => import('@/views/apps/services/service-list/ServiceList'),
          meta: {
            breadcrumb: [
              {title: 'Home', url: '/'},
              {title: 'Services', url: '/a/services'},
              {title: 'List', active: true}
            ],
            pageTitle: 'Service List',
            rule: 'dev',
            authRequired: true
          }
        },
        {
          path: '/a/services/:serviceId',
          name: 'app-service-view',
          component: () => import('@/views/apps/services/ServiceView.vue'),
          meta: {
            breadcrumb: [
              {title: 'Home', url: '/'},
              {title: 'Services', url: '/a/services'},
              {title: 'View', active: true}
            ],
            pageTitle: 'Service View',
            rule: 'dev',
            authRequired: true
          }
        },
        {
          path: '/a/services/:serviceId/edit',
          name: 'app-service-edit',
          component: () => import('@/views/apps/services/service-edit/ServiceEdit.vue'),
          meta: {
            breadcrumb: [
              {title: 'Home', url: '/'},
              {title: 'Services', url: '/a/services'},
              {title: 'Edit', active: true}
            ],
            pageTitle: 'Service Edit',
            rule: 'dev',
            authRequired: true
          }
        },
        {
          path: '/a/users',
          name: 'app-user-list',
          component: () => import('@/views/apps/user/user-list/UserList.vue'),
          meta: {
            breadcrumb: [
              {title: 'Home', url: '/'},
              {title: 'Users', url: '/a/users'},
              {title: 'List', active: true}
            ],
            pageTitle: 'User List',
            rule: 'support_t3',
            authRequired: true
          }
        },
        {
          path: '/a/users/:userId',
          name: 'app-user-view',
          component: () => import('@/views/apps/user/UserView.vue'),
          meta: {
            breadcrumb: [
              {title: 'Home', url: '/'},
              {title: 'Users', url: '/a/users'},
              {title: 'View', active: true}
            ],
            pageTitle: 'User View',
            rule: 'employee',
            authRequired: true
          }
        },
        {
          path: '/a/users/:userId/edit',
          name: 'app-user-edit',
          component: () => import('@/views/apps/user/user-edit/UserEdit.vue'),
          meta: {
            breadcrumb: [
              {title: 'Home', url: '/'},
              {title: 'Users', url: '/a/users'},
              {title: 'Edit', active: true}
            ],
            pageTitle: 'User Edit',
            rule: 'support_t2',
            authRequired: true
          }
        },
        {
          path: '/a/users',
          name: 'app-user-list',
          component: () => import('@/views/apps/user/user-list/UserList.vue'),
          meta: {
            breadcrumb: [
              {title: 'Home', url: '/'},
              {title: 'Users', url: '/a/users'},
              {title: 'List', active: true}
            ],
            pageTitle: 'User List',
            rule: 'support_t3',
            authRequired: true
          }
        },
        {
          path: '/a/users/:userId',
          name: 'app-user-view',
          component: () => import('@/views/apps/user/UserView.vue'),
          meta: {
            breadcrumb: [
              {title: 'Home', url: '/'},
              {title: 'Users', url: '/a/users'},
              {title: 'View', active: true}
            ],
            pageTitle: 'User View',
            rule: 'employee',
            authRequired: true
          }
        },
        {
          path: '/a/users/:userId/edit',
          name: 'app-user-edit',
          component: () => import('@/views/apps/user/user-edit/UserEdit.vue'),
          meta: {
            breadcrumb: [
              {title: 'Home', url: '/'},
              {title: 'Users', url: '/a/users'},
              {title: 'Edit', active: true}
            ],
            pageTitle: 'User Edit',
            rule: 'support_t2',
            authRequired: true
          }
        },


        {
          path: '/a/partners',
          name: 'app-partner-list',
          component: () => import('@/views/apps/partner/partner-list/PartnerList.vue'),
          meta: {
            breadcrumb: [
              {title: 'Home', url: '/'},
              {title: 'Partners', url: '/a/partners'},
              {title: 'List', active: true}
            ],
            pageTitle: 'Partner List',
            rule: 'accountant',
            authRequired: true
          }
        },
        {
          path: '/a/partners/:partnerId',
          name: 'app-partner-view',
          component: () => import('@/views/apps/partner/PartnerView.vue'),
          meta: {
            breadcrumb: [
              {title: 'Home', url: '/'},
              {title: 'Partners', url: '/a/partners'},
              {title: 'View', active: true}
            ],
            pageTitle: 'Partner View',
            rule: 'accountant',
            authRequired: true
          }
        },
        {
          path: '/a/partners/:partnerId/edit',
          name: 'app-partner-edit',
          component: () => import('@/views/apps/partner/partner-edit/PartnerEdit.vue'),
          meta: {
            breadcrumb: [
              {title: 'Home', url: '/'},
              {title: 'Partners', url: '/a/partners'},
              {title: 'Edit', active: true}
            ],
            pageTitle: 'Partner Edit',
            rule: 'accountant',
            authRequired: true
          }
        },
        {
          path: '/a/partners/:partnerId/products',
          name: 'app-partner-list-products',
          component: () => import('@/views/apps/self-partner/product-list/ProductList.vue'),
          meta: {
            breadcrumb: [
              {title: 'Home', url: '/'},
              {title: 'Partners', url: '/a/products'},
              {title: 'List Products', active: true}
            ],
            pageTitle: 'Product List',
            rule: 'accountant',
            authRequired: true
          }
        },

        // =============================================================================
        // Pages Routes
        // =============================================================================
        {
          path: '/settings',
          name: 'user-settings',
          component: () => import('@/views/pages/user-settings/UserSettings.vue'),
          meta: {
            breadcrumb: [
              {title: 'Home', url: '/'},
              {title: 'User Settings', active: true}
            ],
            pageTitle: 'Settings',
            rule: 'user',
            authRequired: true
          }
        },
        {
          path: '/faq',
          name: 'page-faq',
          component: () => import('@/views/pages/Faq.vue'),
          meta: {
            breadcrumb: [
              {title: 'Home', url: '/'},
              {title: 'FAQ', active: true}
            ],
            pageTitle: 'FAQs',
            rule: 'visitor',
            authRequired: true
          }
        },
        {
          path: '/a/faq',
          name: 'page-faq-edit',
          component: () => import('@/views/apps/faq/FAQList'),
          meta: {
            breadcrumb: [
              {title: 'Home', url: '/'},
              {title: 'FAQ', active: true}
            ],
            pageTitle: 'FAQ Edit',
            rule: 'editor',
            authRequired: true
          }
        },
        {
          path: '/a/blogs',
          name: 'page-blog-list',
          component: () => import('@/views/apps/blog/BlogList.vue'),
          meta: {
            breadcrumb: [
              {title: 'Home', url: '/'},
              {title: 'Blog', active: true}
            ],
            pageTitle: 'Blog',
            rule: 'editor',
            authRequired: true
          }
        },
        {
          path: '/a/blogs/create',
          name: 'page-blog-create',
          component: () => import('@/views/apps/blog/BlogCreate'),
          meta: {
            breadcrumb: [
              {title: 'Home', url: '/'},
              {title: 'Blog', url: '/a/blogs'},
              {title: 'Create Blog', active: true}
            ],
            pageTitle: 'Create Blog',
            rule: 'editor',
            authRequired: true
          }
        },
        {
          path: '/a/blogs/:id',
          name: 'page-blog-view',
          component: () => import('@/views/apps/blog/BlogEdit'),
          meta: {
            breadcrumb: [
              {title: 'Home', url: '/'},
              {title: 'Blog', url: '/a/blogs'},
              {title: 'Edit Blog', active: true}
            ],
            pageTitle: 'Edit Blog',
            rule: 'editor',
            authRequired: true
          }
        }
      ]
    },
    // =============================================================================
    // FULL PAGE LAYOUTS
    // =============================================================================
    {
      path: '',
      component: () => import('@/layouts/full-page/FullPage.vue'),
      children: [
        // =============================================================================
        // PAGES
        // =============================================================================
        {
          path: '/login',
          name: 'page-login',
          component: () => import('@/views/pages/login/Login.vue'),
          meta: {
            rule: 'visitor',
            pageTitle: 'Login'
          }
        },
        {
          path: '/register',
          name: 'page-register',
          component: () => import('@/views/pages/register/Register.vue'),
          meta: {
            rule: 'visitor',
            pageTitle: 'Register'
          }
        },
        {
          path: '/authorize',
          name: 'authorize-application',
          component: () => import('./views/pages/Authorize'),
          meta: {
            rule: 'user',
            pageTitle: 'Authorize App',
            authRequired: true
          }
        },
        {
          path: '/discord',
          name: 'authorize-discord',
          component: () => import('./views/pages/DiscordAuthorize'),
          meta: {
            rule: 'user',
            pageTitle: 'Authorize Discord',
            authRequired: true
          }
        },
        {
          path: '/forgot-password',
          name: 'page-forgot-password',
          component: () => import('@/views/pages/ForgotPassword.vue'),
          meta: {
            rule: 'visitor',
            pageTitle: 'Forgot Password'
          }
        },
        {
          path: '/forgot-password/:code?',
          name: 'page-forgot-password-confirm',
          component: () => import('@/views/pages/ForgotPasswordConfirm.vue'),
          meta: {
            rule: 'visitor',
            pageTitle: 'Confirm Password'
          }
        },
        {
          path: '/confirm/:code?',
          name: 'page-confirm-email',
          component: () => import('@/views/pages/ConfirmEmail.vue'),
          meta: {
            rule: 'visitor',
            pageTitle: 'Confirm Email'
          }
        },
        {
          path: '/error-500',
          name: 'page-error-500',
          component: () => import('@/views/pages/Error500.vue'),
          meta: {
            rule: 'visitor',
            pageTitle: 'Error 500'
          }
        },
        {
          path: '/not-authorized',
          redirect: '/login'
        },
        {
          path: '/maintenance',
          name: 'page-maintenance',
          component: () => import('@/views/pages/Maintenance.vue'),
          meta: {
            rule: 'visitor',
            pageTitle: 'Maintenance'
          }
        }
      ]
    },
    // Redirect to 404 page, if no match found
    {
      path: '*',
      name: 'page-error-404',
      component: () => import('@/views/pages/Error404.vue')
    }
  ]
})

router.afterEach((to, from) => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
  if (to.path !== '/login' && to.path !== '/register' && !to.path.startsWith('/confirm') && !to.path.startsWith('/forgot-password')) {
    store.dispatch('auth/refreshUserObject')
      .catch(e => {
        console.error(`Failed to refresh user object, and we are not on an auth related page:`, e);

        localStorage.removeItem('accessToken')
        localStorage.removeItem('refreshToken');

        router.push('/login')
      })
  }

  let name;

  if (to.meta.pageTitle) {
    name = to.meta.pageTitle
  } else {
    name = to.name.replaceAll('-', ' ')
    if (this && this.$options && this.$options.filters) {
      name = this.$options.filters.title(name);
    }
  }

  document.title = `${name} | Rapid Portal`

  if (to.query.referrer) {
    localStorage.setItem('referrer', to.query.referrer)
  }

})

router.beforeEach((to, from, next) => {

  if (to.path === '/login' || to.path === '/register') {

    if (localStorage.getItem('accessToken') !== null) {
      return router.push({path: '/'})
    }
    return next()
  }

  // If auth required, check login. If login fails redirect to login page
  if (to.meta.authRequired) {
    if (!localStorage.getItem('accessToken')) {
      localStorage.setItem('referring_url', to.fullPath)
      return router.push({path: '/login'})
    }

    const redirectTo = localStorage.getItem('referring_url');
    if (redirectTo) {
      localStorage.removeItem('referring_url');
      return router.push(redirectTo);
    }
  }

  return next()
})

export default router
