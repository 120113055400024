import Vue from 'vue'
import {AclCreate, AclInstaller, AclRule} from 'vue-acl'
import router from '@/router'
import store from '@/store/store'

Vue.use(AclInstaller)

let initialRole = 'visitor'

const userInfo = JSON.parse(localStorage.getItem('userInfo'))
if (userInfo && userInfo.role) initialRole = userInfo.role

export default new AclCreate({
  initial: initialRole || 'visitor',
  notfound: {
    path: '/not-authorized',
    forwardQueryParams: true
  },
  router,
  acceptLocalRules: true,
  globalRules: {
    visitor: new AclRule('visitor')
      .or('user')
      .or('partner')
      .or('employee')
      .or('support_t1')
      .or('support_t2')
      .or('support_t3')
      .or('editor')
      .or('analyst')
      .or('proctor')
      .or('accountant')
      .or('dev')
      .or('sys_admin')
      .or('admin')
      .generate(),
    user: new AclRule('user')
      .or('partner')
      .or('employee')
      .or('support_t1')
      .or('support_t2')
      .or('support_t3')
      .or('editor')
      .or('analyst')
      .or('proctor')
      .or('accountant')
      .or('dev')
      .or('sys_admin')
      .or('admin')
      .generate(),
    partner: new AclRule('partner')
      .or('proctor')
      .or('accountant')
      .or('dev')
      .or('sys_admin')
      .or('admin')
      .generate(),
    employee: new AclRule('employee')
      .or('support_t1')
      .or('support_t2')
      .or('support_t3')
      .or('editor')
      .or('analyst')
      .or('proctor')
      .or('accountant')
      .or('dev')
      .or('sys_admin')
      .or('admin')
      .generate(),
    support_t1: new AclRule('support_t1')
      .or('support_t2')
      .or('support_t3')
      .or('proctor')
      .or('dev')
      .or('sys_admin')
      .or('admin')
      .generate(),
    support_t2: new AclRule('support_t2')
      .or('support_t3')
      .or('proctor')
      .or('dev')
      .or('sys_admin')
      .or('admin')
      .generate(),
    support_t3: new AclRule('support_t3')
      .or('proctor')
      // .or('dev')
      .or('sys_admin')
      .or('admin')
      .generate(),
    editor: new AclRule('editor')
      .or('proctor')
      .or('sys_admin')
      .or('admin')
      .generate(),
    proctor: new AclRule('proctor')
      .or('sys_admin')
      .or('admin')
      .generate(),
    accountant: new AclRule('accountant')
      .or('sys_admin')
      .or('admin')
      .generate(),
    dev: new AclRule('dev')
      .or('sys_admin')
      .generate(),
    sys_admin: new AclRule('sys_admin')
      .generate(),
    admin: new AclRule('admin')
      .or('sys_admin')
      .or('dev') // TODO: Temporary until we move into a prod env and Cameron doesn't need access to shit anymore
      .generate(),
  },
  middleware: async acl => {
    return store.dispatch('auth/refreshUserObject', acl)
      .catch(e => {
        console.error(`Failed to refresh user object in acl:`, e);
      })
  }
})
