/*=========================================================================================
  File Name: moduleEcommerceActions.js
  Description: Ecommerce Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

export default {
  toggleItemInCart ({ getters, commit, dispatch }, item) {
    getters.isInCart(item.id) ? commit('REMOVE_ITEM_FROM_CART', item) : dispatch('additemInCart', item)
  },
  additemInCart ({ commit }, item) {

    // Below properties should be added as per requirement from back-end
    // This is added just for demo purpose
    item['quantity'] = 1
    commit('ADD_ITEM_IN_CART', item)
  },
  updateItemQuantity ({ commit }, payload) {
    commit('UPDATE_ITEM_QUANTITY', payload)
  }
}
