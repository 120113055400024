/*=========================================================================================
  File Name: moduleEcommerceState.js
  Description: Ecommerce Module State
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

/* eslint-disable */
export default {
    cartItems: [],
}
/* eslint-enable */
